import "./style.scss";

import { graphql } from "gatsby";
import {
  outsideLink,
  paragraph,
  renderCom,
} from "../../components/ForMarkDownComponents";

import Container from "../../components/Container";
import GeneralForm from "../../components/Forms/_main";
import Layout from "../../layouts";
import { createClassName } from "../../helpers";

const renderComToolkit = {
  p: paragraph,
  "outside-link": outsideLink,
};

const contactRenderCom = renderCom(renderComToolkit);

const cn = createClassName("contact");

const Contact = ({ data }) => {
  const { htmlAst, frontmatter } = data.markdownRemark;

  return (
    <Layout {...frontmatter.seo[0]}>
      <Container className={cn({ wrap: true })}>
        <GeneralForm
          heading={frontmatter.heading}
          bigHeading
          title={frontmatter.title}
          subTitleLevel={5}
          classNameForm={cn("form", { wrap: true })}
          classNameSubmit={cn("form", { submit: true })}
          textareaPlaceholder={frontmatter.textareaPlaceholder}
        />
        <div className={cn("text")}>{contactRenderCom(htmlAst)}</div>
      </Container>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query getContacts($path: String! $siteLanguage: String!) {
    markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      htmlAst
      frontmatter {
        seo {
          title
          description
          link
        }
        bannerTitle
        heading
        title
        textareaPlaceholder
      }
    }
  }
`;
